import { ShoppingBag, SportsMotorsports } from "@mui/icons-material";

import AddressLine from "../web_pages/RestaurantPage/RestDetails/addressLine";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';




const getOrderTotalPrice = (theOrder) => {

    let tot = 0;

    tot = getOrderPrice(theOrder);

    //משלוח
    if (theOrder.OrderType == "Deliver") {
        tot += parseInt(theOrder.OrderDelPrice);
    }

    //קופון
    if (theOrder.orderKopon && theOrder.orderKopon !== "") {

        let koponPrice = (theOrder.orderKopon.KoponDiscType === '1')
            ? parseInt(theOrder.orderKopon.KoponDiscount) * -1
            : parseInt(((getOrderPrice(theOrder) / 100) * parseInt(theOrder.orderKopon.KoponDiscount)) * -1)

        tot += parseInt(koponPrice);
    }

    //זיכויים
    if (theOrder.orderZikoy && theOrder.orderZikoy.length > 0) {
        theOrder.orderZikoy.forEach(element => {
            let zikoyPrice = parseInt(element.zickoyPrice) * -1;
            tot += parseInt(zikoyPrice);
        });
    }

    return tot;
}


const getOrderPrice = (theOrder) => {
    let tot = 0;

    theOrder.Orders.map((item, index) => {
        let addsPrice = 0;

        if (item.splitSelection != true) {
            if (item.specials.length > 0) {
                item.specials.map((special, specialIndex) => {
                    let freeCount = 0;

                    //בודק אם יש חינמים
                    special.spec.map((spec, specIndex) => {

                        if (special.freeAdds != null && parseInt(special.freeAdds) > 0) {

                            for (let i = 0; i < spec.itmsMount; i++) {
                                freeCount++;
                                if (freeCount <= parseInt(special.freeAdds)) {

                                }
                                else {
                                    if (parseFloat(spec.addToPrice) > -1) {
                                        addsPrice += (parseFloat(spec.addToPrice))
                                    }
                                }
                            }

                        }
                        else {
                            for (let i = 0; i < spec.itmsMount; i++) {
                                if (parseFloat(spec.addToPrice) > -1) {
                                    addsPrice += (parseFloat(spec.addToPrice)) 
                                }
                            }
                        }
                    })
                })
            }

        }

        tot += (parseFloat(item.prodPrice) + parseFloat(addsPrice)) * parseInt(item.mount);

    })
    tot += parseFloat(getStickedSauces(theOrder));

    return tot;
}


//בדיקת חינמים
const getPriceAfterFree = (freeAdds, fullsSelc, defaultprice) => {

    let priceAfterFix = defaultprice;

    if (fullsSelc / 2 <= freeAdds) {
        priceAfterFix = 0;
    }
    return (priceAfterFix)
}

// בדיקת חינמים בפיצה
const getPizzaPriceAfterFree = (currntItm, theLst, theItem, checkerSplit) => {
    // console.log(freeAdds)
    // console.log(theLst)

    // console.log("----------------------")
    // console.log(theLst)
    // console.log(currntItm)
    // console.log(theItem)


    //כמה שלמים
    let theFulls = theLst.filter(it =>
        it.splitSelected == null ||
        it.splitSelected == "" ||
        it.splitSelected == "full")

    //ריצה על כל השלמים
    for (let j = 0; j < theFulls.length; j++) {
        const element = theFulls[j];
        let sourcePrice = theItem.options.find((it, index) => it.servType == element.servType).addToPrice
        let theIndx = theLst.findIndex(ind => ind.servType == theFulls[j].servType)
        theLst[theIndx].addToPrice = sourcePrice.toString()
    }

    //כמה חצויים
    let theSpls = theLst.filter(it =>
        it.splitSelected != null &&
        it.splitSelected != "" &&
        it.splitSelected != "full")

    //ריצה על כל החצויים
    for (let i = 0; i < theSpls.length; i++) {
        let theIndx = theLst.findIndex(ind => ind.servType == theSpls[i].servType)
        let sourcePrice = theItem.options.find((it, index) => it.servType == theSpls[i].servType).addToPrice

        if (theSpls.length % 2 == 0) {
            theLst[theIndx].addToPrice = (sourcePrice / 2).toString()
        }
        else {
            if (i != theSpls.length - 1) {
                theLst[theIndx].addToPrice = (sourcePrice / 2).toString()
            }
            else {
                theLst[theIndx].addToPrice = sourcePrice.toString()
            }
        }
    }


    // if (fullsSelc / 2 <= freeAdds) {
    //     priceAfterFix = 0;
    // }

    return (theLst)
}



const takeAwayBlock = (theTime, Address) => {
    return (
        <>
            <div className={"takeAwayBlock"}>
                <div className={"theImg"}>
                    <ShoppingBag />
                </div>
                {/* <div className={"theImg"} style={{ backgroundImage: `url("/PermanentImages/take.png")` }}></div> */}
                <div className={"txtOrdType"}>

                    <div className={"bold pickOrDel"} >איסוף עצמי</div>
                    <div className={"regular"} >{theTime}</div>
                    {/* <div className={"regular"} ><AddressLine address={Address} typeDisp="NoIcon" /></div> */}

                </div>


            </div>
        </>
    )
}


const deliveryBlock = (theTime, thePrice) => {
    return (
        <>
            <div className={"takeAwayBlock"}>
                <div className={"theImg"}>
                    <SportsMotorsports />
                </div>
                <div className={"txtOrdType"}>
                    <div className={"bold pickOrDel"} >משלוח</div>
                    <div className={"regular"} >{theTime}</div>
                    <div className={"regular"} >מחיר משלוח:  ₪{thePrice}</div>
                </div>
            </div>
        </>
    )
}

const theItemSpecials = (item) => {

    let totBk = (item.specials.length > 0) ?
        item.specials.map((special, specialIndex) => {

            let specCount = 0;
            let freeCount = 0;
            let specc = special.spec.map((spec, specIndex) => {
                let addsPrice = 0;

                if (spec.addToPrice > -1) {
                    specCount++;
                    //בודק אם יש חינמים
                    if (special.freeAdds != null && parseInt(special.freeAdds) > 0) {
                        for (let i = 0; i < spec.itmsMount; i++) {
                            freeCount++;
                            if (freeCount <= parseInt(special.freeAdds)) {
                            }
                            else {
                                if (parseFloat(spec.addToPrice) > -1) {
                                    addsPrice += parseInt(spec.addToPrice) * item.mount
                                }
                            }
                        }
                    }
                    else {
                        for (let i = 0; i < spec.itmsMount; i++) {
                            if (parseFloat(spec.addToPrice) > -1) {
                                addsPrice += (parseFloat(spec.addToPrice))
                            }
                        }
                    }


                    return (
                        <div key={specIndex} className={"ordItmLine ordItmOptHead"}>
                            <span className={"rSideMiddle"}>
                                {pizzConten(spec, item)}
                                <span className={"regular"}> {spec.servType}</span>
                                <span className={"bold"}>
                                    {item.mount > 1 ? `  (* ${item.mount})` : ""}</span>

                            </span>

                            <span className={"bold lSideSmall"}>
                                {addsPrice > 0 ? "₪ " + (addsPrice) + " +" : ""}
                            </span>
                        </div>
                    )
                }
            })
            if (specCount > 0) {
                return (
                    <div key={specialIndex} className={"ordItmSpecLine spacePadSmall"}>
                        <div className={"ordItmOptHead spacePadSmall bold"} >{special.optionName}</div>
                        {specc}
                    </div>
                )
            }

        })
        : ""
    return totBk;
}

const clearOrd = () => {
    let ord = { restId: "", currentP: [], Orders: [] };

    let stringOrd = JSON.stringify({ ...ord });
    sessionStorage.setItem("am_order", stringOrd)

    return (ord);

}


//תוכן חלוקת המגש - בפיצריות
const pizzConten = (spec, item) => {
    if (spec.splitSelected != null) {
        let bk = "";
        switch (spec.splitSelected) {
            case "lHalf":
                {
                    bk = "חצי שמאל";
                    break;
                }
            case "rHalf":
                {
                    bk = "חצי ימין";
                    break;
                }
            case "rtQuart":
                {
                    bk = "רבע ימין עליון";
                    break;
                }
            case "rbQuart":
                {
                    bk = "רבע ימין תחתון";
                    break;
                }
            case "ltQuart":
                {
                    bk = "רבע שמאל עליון";
                    break;
                }
            case "lbQuart":
                {
                    bk = "רבע שמאל תחתון";
                    break;
                }
            default:
                bk = "כל המגש";
                break;

        }
        return (<span className={"regular"}>{bk}</span>)
    }
    else {
        return (<span className={"regular"}>{spec.itmsMount > 0 ? `X ${spec.itmsMount} ` : ""}</span>)
    }

}


//בודק אם יש רטבים מוצמדים ואם הלקוח עבר את המכסה החינמית
//במידה ועבר, יוחזר ההפרש שיש לתשלום 
const getStickedSauces = (theOrder) => {
    let priceToAdd = 0;
    let fSauces = (theOrder.freeSaucess != null) ? theOrder.freeSaucess : 0;
    let countIt = 0;
    theOrder.StickedSauces?.forEach(sauce => {

        for (let i = 0; i < sauce.mount; i++) {
            countIt++;
            if (countIt > fSauces) {
                priceToAdd += parseFloat((sauce.addToPrice != "") ? sauce.addToPrice : 0);
            }
        }

    });

    return priceToAdd;

}



//אופן תשלום
const payingTYpe = (payt) => {

    switch (payt) {
        case "1":
            return "אשראי";
        case "2":
            return "מזומן";
        case "3":
            return "ביט";
    }
}

export default {
    getOrderTotalPrice, getOrderPrice,
    takeAwayBlock, deliveryBlock, clearOrd, getStickedSauces,
    pizzConten,
    theItemSpecials,
    getPizzaPriceAfterFree,
    getPriceAfterFree,
    payingTYpe


};