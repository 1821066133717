import { ArrowCircleRightSharp, CancelRounded, DirectionsBike } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from 'react-router-dom';
import { Link, Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import ConnUrls from "../../Connections/ConnectURL";
import { CurrentPContext, MasterContext, MessagesObjCssContext, MobiOrNotContext, OrderContext, UserContext } from "../../ContextApi/context";
import BigPopUpMsg from "../../DTechComp/BigPopUpMsg";
import ContinueToPayLine from "../../DTechComp/ContinueToPayLine";
import MyDateTime from "../../DTechComp/MyDateTime";
import ScrollingSaver from "../../DTechComp/ScrollingSaver";
import OrderFunctions from "../../OrderSettings/OrderFunctions";
import utilsRests from "../../_Utils/utilsRests";
import myFunctions from "../../myFunctions";
import MoreDetails from "./MoreDetails";
import BuisRating from "./RestDetails/BuisRating";
import DayOpeningHours from "./RestDetails/DayOpeningHours";
import AddressLine from "./RestDetails/addressLine";
import RestMnu from "./RestMnu";
import PopUpMsg from "../../DTechComp/PopUpMsg";
import TopBigSlides from "../../DTechComp/TopBigSlides";
import TblOrderReservation from "./ForTableReservation/TblOrderReservation";
import PopUpTheComp from "../../DTechComp/PopUpTheComp";
import utilsOrders from "../../_Utils/utilsOrders";
import utilsUsers from "../../_Utils/utilsUsers";
import PopUp2Buttons from "../../DTechComp/PopUp2Buttons";
import TopSmallSlides from "../../DTechComp/TopSmallSlides";



const RestaurantPage = (props) => {

    let { path, url } = useRouteMatch();
    const history = useHistory();
    // const [theData] = useState(TheSrc());
    const [user] = useContext(UserContext);
    const [order, setOrder] = useContext(OrderContext);
    const [master, setMaster] = useContext(MasterContext);
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    const [currentPage, setCurrentPage] = useContext(CurrentPContext);
    const [openingHours, setOpeningHours] = useState();
    const [buisAddress, setBuisAddress] = useState();
    const [delTiming, setDelTiming] = useState();
    const [rating, setRating] = useState();
    const [doDeliver, setDoDeliver] = useState(currentPage.Delivery?.Doing);
    const [doTakeAway, setDoTakeAway] = useState(currentPage.TakeAway?.Doing);
    const [stickyShow, setStickyShow] = useState(false);
    const [mousePosi, setMousePosi] = useState({ x: "5px", y: "43%" });
    const [mov, setMov] = useState(false);

    const [restMnu, setRestMnu] = useState();
    const [moreDetailsPage, setMoreDetailsPage] = useState();
    const [showHideMoreDet, setShowHideMoreDet] = useState("hideOnLeft");
    const [lsidetuff, setLsideStuff] = useState();

    const [loading, setLoading] = useState(true);
    const [thePopUp, setThePopUp] = useState("");

    const params = useParams();
    // const location = useLocation();

    const [bgImgPos, setBgImgPos] = useState("");
    const [waitImgItm, setWaitImgItm] = useState({});
    const [shHidMoreDetails, setShHidMoreDetails] = useState(false);

    const [dispOnlyDelivery, setDispOnlyDelivery] = useState(true);
    const [googleSchem, setGoogleSchem] = useState();

    const [buissMainImgDeskTop, setBuissMainImgDeskTop] = useState(currentPage.buissMainImgDeskTop);
    const [buissMainImg, setBbuissMainImg] = useState(currentPage.buissMainImg);

    const [notGettinOrders, setNotGettinOrders] = useState("");

    const [userLast2Ordrs, setUserLast2Ordrs] = useState([]);

    const [thePrompt, setThePrompt] = useState()

    const [messagesObjCss, setMessagesObjCss, goSetTheMsgDisp] = useContext(MessagesObjCssContext);

    useEffect(() => {
        goSetTheMsgDisp();
    }, [])
    // const lochrf = window.location.pathname;

    useEffect(() => {

        setWaitImgItm(myFunctions.setTheImgWait(currentPage, master));

        if (order.Orders <= 0) props.scrollToTop();
        props.shoTopStrp(false)

        setPrompt();
        checkUserBeenhere();
        setLoading(false);
    }, []);

    useEffect(() => {

        getRestData();
        setLoading(false)

    }, [])


    //אם עוד פרטים מוצג כפתור החזרה יחזיר ישירות לעמוד העסק
    useEffect(() => {

        const handlePopState = (event) => {
            if (shHidMoreDetails) {
                history.push(props.refrshPath)
            }
        };

        // window.removeEventListener('popstate', handlePopState);
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [shHidMoreDetails]);


    useEffect(() => {

        if (currentPage != null) {
            creatGoogleSchema()

            setBuissMainImgDeskTop(currentPage.buissMainImgDeskTop);
            setBbuissMainImg(currentPage.buissMainImg);
        }

        // setStickyShow(
        //     (!master.blockDeliverys ||
        //         !master.blockPickUps) ?
        //         currentPage.Delivery?.Doing ||
        //             currentPage.TakeAway?.Doing ?
        //             true : false
        //         : false)

    }, [currentPage])

    //בודק אם לקוח כבר היה פה ויש לו הזמנות מהעסק הנוכחי אולי הוא רוצה פשוט לשכפל הזמנה
    const checkUserBeenhere = async () => {
        // if (user._id) {
        //     let userHaveorders = await utilsUsers.chekUserOrders(user._id, props.restId);
        //     setThePopUp(
        //         <BigPopUpMsg
        //             class={"oops"}
        //             key={popTiming}
        //             imgSrc={`${ConnUrls.servMediasUrl}${master.oopsPopImg}`}
        //             Title={["כבר הזמנת מפה פעם"]}
        //             ContentText={["היית רוצה לבצע הזמנה זהה לקודמת?"]}
        //             inLabel={""}
        //             okClick={() => history.push("/MyOrders")}
        //             BtnTxt={"כן, תראה לי את ההזמנות הקודמות שלי"}
        //             btnClick2={() => setThePopUp()}
        //             BtnTxt2={"לא, אני רוצה לבצע הזמנה חדשה"}
        //             errorMsg={""} />
        //     )
        // }
    }

    const creatGoogleSchema = () => {
        if (currentPage.buissName) {
            let theSchem =
            {
                "@context": "https://schema.org/",
                "@type": "Restaurant",
                "name": currentPage.buissName,
                "image": ConnUrls.servMediasUrl + currentPage.buissMainImg,
                "telephone": myFunctions.validPhone(currentPage.buissPhone) ? currentPage.buissPhone : "0777017003",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": currentPage.buissAddress ? myFunctions.getOnlyaddress({ address: currentPage.buissAddress }).mainAddress : "",
                    "addressLocality": "אשדוד",
                    "addressCountry": "IL"
                },
                "priceRange": "10-150",
                "description": currentPage.sEOdescription,
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "bestRating": "5",
                    "ratingValue": currentPage.buissRating !== "" ? currentPage.buissRating : "4",
                    "worstRating": "1",
                    "reviewCount": 98
                },
                "servesCuisine": currentPage.buissKitchType,
                "openingHoursSpecification": currentPage.OpeningHours.map((element) => ({
                    "@type": "OpeningHoursSpecification",
                    "dayOfWeek": element.Day,
                    "opens": element.Open,
                    "closes": element.Close
                })),
                "additionalProperty": {
                    "@type": "PropertyValue",
                    "name": "About",
                    "value": currentPage.buissAbout
                },
                "hasMenu": ConnUrls.siteDomainNam + `/restaurants/${currentPage.pageUrlFix !== null && currentPage.pageUrlFix !== "" ? currentPage.pageUrlFix : currentPage._id}`,
                "paymentAccepted": "אשראי, ביט, מזומן",
                "url": ConnUrls.siteDomainNam + `/restaurants/${currentPage.pageUrlFix !== null && currentPage.pageUrlFix !== "" ? currentPage.pageUrlFix : currentPage._id}`
            }


            setGoogleSchem(JSON.stringify(theSchem))
        }

    }

    const popMoreDetails = () => {
        setShHidMoreDetails(!shHidMoreDetails)
    }

    const getRestData = async () => {

        let temp_doDeliver = true;
        let temp_doTakeAway = true;

        try {
            let restPage = await utilsRests.getRestByIdWitUnPublished(props.restId);
            if (restPage == null) {
                setBigPop();
            }

            else if (restPage != null) {
                if (restPage.restStatus == "לא פעיל") {
                    setBigPop("לא פעיל");
                }
                else if (!MyDateTime.checkIfBetweenHours(restPage).openOrCLose) {
                    setBigPop("סגור");
                }

                setCurrentPage({ ...restPage, pageTYp: "withConPay" });


                if (!MyDateTime.checkIfBetweenHours(restPage).openOrCLose) {
                    setDoDeliver(false)
                    setDoTakeAway(false)

                    temp_doDeliver = false;
                    temp_doTakeAway = false;

                }
                else {


                    let Deliver = (restPage.Delivery.Doing && MyDateTime.checkIfBetweenDeliveryHours(restPage).openOrCLose)
                    //בודק השהיית משלוחים
                    Deliver = !restPage.closeDeliverDelay || restPage.closeDeliverDelay.delaydVal === "0" ? Deliver : false;

                    let Take = restPage.TakeAway.Doing;
                    //בודק השהיית איסופים
                    Take = !restPage.closeDeliverDelay || restPage.closeTakeDelay.delaydVal === "0" ? Take : false;

                    setDoDeliver(Deliver)
                    setDoTakeAway(Take)

                    temp_doDeliver = Deliver;
                    temp_doTakeAway = Take;
                }

                setOpeningHours(<DayOpeningHours item={restPage} />);
                setBuisAddress(restPage.buissAddress ? <AddressLine address={restPage.buissAddress} /> : "");

                let timing = (restPage.Delivery?.Time) ? restPage.Delivery.Time : (restPage.TakeAway?.Time) ? restPage.TakeAway.Time : 30;
                timing = myFunctions.checkForDeliverysDelay(master, timing);

                let delTim = (
                    <div className={"pgSmallTxt"} >
                        <div className={"theImgwrap"} >
                            <div className={"theImg onRight"} style={{ paddingLeft: "10px", marginRight: "-4px" }} >
                                <DirectionsBike />
                            </div>
                        </div>
                        <div className={"timeTxt"}>
                            {(parseInt(timing) + 10) +
                                " - " + parseInt(timing)} דק'
                        </div>
                    </div >
                )
                setDelTiming(delTim)

                setRating(<BuisRating rating={restPage.buissRating} />);

                setRestMnu(<RestMnu buisMenu={restPage.buisMenu}
                    showMoreDet={() => { setShHidMoreDetails(!shHidMoreDetails) }}
                    nameBuis={restPage.buissName}
                    restPage={restPage}
                    delTiming={delTim}
                    doDeliver={temp_doDeliver}
                    doTakeAway={temp_doTakeAway}
                    dispOnlyDelivery={dispOnlyDelivery} BuisId={restPage._id}
                    setDispOnlyDelivery={() => setDispOnlyDelivery(!dispOnlyDelivery)}
                    orderMeATable={() => orderMeATable()} />
                );


                setLsideStuff(
                    <>
                        <a href={"#"} onClick={() => window.open('tel:' + restPage.buissPhone, '_system')} className={"overFlHid"}>
                            <div className={"phoneLine"}>
                                <div className="clickPhoneBtn">{restPage.buissPhone}</div>
                            </div>
                        </a>
                        {delTiming}
                        <div className={"detailsLine moreDetBtn"}>
                            <div className={"bold"} onClick={() => popMoreDetails()}>
                                <span>עוד פרטים</span>
                            </div>
                        </div>

                        <div className={"detailsLine moreDetBtn"}>
                            <div className={"bold"}>
                                {/* <span>הזמנה עתידית</span> */}
                            </div>
                        </div>
                        <div className={"detailsLine moreDetBtn"}>
                            {/* <div className={"bold"} onClick={() => setDispOnlyDelivery(!dispOnlyDelivery)}>
                                <span>{(dispOnlyDelivery) ? "הצג תפריט מלא" : "הצג תפריט משלוחים"} </span>
                            </div> */}
                        </div>
                    </>)

                setWaitImgItm(myFunctions.setTheImgWait(restPage, master));

                setNotGettinOrders(myFunctions.notGettinOrders(master, MyDateTime.checkIfBetweenHours(restPage), restPage));
            }

        }
        catch (error) {
            // console.log(error)
            setBigPop("לא פעיל");
        }


    }

    let popTiming = -1;
    const setBigPop = (typ) => {
        popTiming++;
        if (typ === "סגור") {

        }
        else {
            setThePopUp(

                <PopUpMsg key={popTiming}
                    nosnippet={true}
                    Title={["אופססס!!!"]}
                    cssStyl={"okPOP"}
                    topSlides={<TopBigSlides TopImages={[{ img: master.oopsPopImg, txt: "" }]} />}
                    ContentText={["עסק זה כרגע לא פעיל",
                        "להזמנות באתר"]}
                    inLabel={""}
                    okClick={() => { history.push(`/`) }}
                    btn1Css={"btnGreen bold btnPoppadding15"}
                    errorMsg={""} />

            )
        }
    }

    const orderMeATable = () => {
        setThePopUp(
            <PopUpTheComp
                // closePop={() => { props.stickyShow(true); setThePopUp() }}
                closePop={() => { setThePopUp() }}
                cssStyl={"OrderTblPop onTopOfAll"}
                theInsideComp={
                    <TblOrderReservation closeMe={() => setThePopUp()} />}
                Title={[""]}
            />
        )
    }

    const goToBack = () => {

        if (order.Orders?.length > 0) {
            setThePopUp(
                <PopUp2Buttons
                    cssStyl={"specialPopComp onTopOfAll noPadding"}
                    key={popTiming}
                    // topTitle={"שים לב"}
                    topSlides={<TopSmallSlides TopImages={[{ img: master.remarkIconPopImg, txt: "" }]} />}
                    Title={["יש הזמנה פעילה"]}
                    ContentText={["יציאה תוביל לאובדן הנתונים"]}
                    inLabel={""}
                    closeFunc={() => setThePopUp()}
                    btn1Clck={() => setThePopUp()}
                    btn1Txt={"המשך להזמין"}
                    btn2Txt={"צא בכל זאת"}
                    btn2Clck={() => {
                        sessionStorage.setItem("restPage", "");
                        setOrder(OrderFunctions.clearOrd())
                        history.push("/");
                    }}
                />
            )

        }
        else {
            sessionStorage.setItem("restPage", "");
            history.push("/");
        }

        return ""
    }


    const setPrompt = () => {
        setThePrompt(
            // <Prompt
            //     // when={shouldBlockNavigation}
            //     message={(location, action) => {
            //         if (order.Orders?.length > 0) {
            //             return false;
            //         }
            //         else {
            //             sessionStorage.setItem("restPage", "");
            //             return true;
            //         }

            //     }}
            // />
        )
    }


    let onlyPhoneOrders = (currentPage.buissIsOnline != null && currentPage.buissIsOnline) ? "" : "כרגע, הזמנות טלפונית";

    return (
        <div className="inerPage">

            <div className={loading ? "imgWaiting" :
                `imgWaiting animate__animated animate__fast animate__zoomOut ${waitImgItm.imgDelay} ${waitImgItm.imgWaitingSpecial}`}
                style={{
                    backgroundImage: `url(${ConnUrls.servMediasUrl}${waitImgItm.waitImg})`,
                    backgroundPosition: `${waitImgItm.imgBackPos}`,
                    backgroundSize: `${(!mobiOrNot) ? "25%" : waitImgItm.backgroundSize}`,
                    backgroundRepeat: `${waitImgItm.backgroundRepeat}`
                }}>
            </div>

            {loading ? "" :
                <div className={"restWrap"}>

                    {/* {stickyShow ? <>
                        <div id="stickeySideMsg" className={"stickeySideMsg"} style={{
                            left: mousePosi.x,
                            top: mousePosi.y,
                            backgroundImage: `url(${ConnUrls.servMediasUrl}Uploads/MASTER/onlne.png)`,
                            backgroundPosition: `${waitImgItm.imgBackPos}`,
                            backgroundSize: `85%`,
                            backgroundRepeat: `${waitImgItm.backgroundRepeat}`
                        }}
                            onMouseDown={() => setMov(true)}
                            onMouseUp={() => setMov(false)}>
                            //{mov ? <MovingOnScreen whenMove={(posi) => movposi(posi)} /> : ""} //
                            <CancelRounded className={"HighlightOffSticky pointing"} onClick={() => setStickyShow(false)} />
                        </div>

                    </>
                        : ""} */}

                    {(mobiOrNot) ?
                        <div className={`backSdOnlyBtn`}> <ArrowCircleRightSharp onClick={() => goToBack()} /></div>
                        : ""}

                    <div className={"imgWrap mainRestP posRelative"}>
                        <div className={"imgRestDiv"} style={{
                            backgroundImage:
                                `url("${ConnUrls.servMediasUrl}${(!mobiOrNot && buissMainImgDeskTop != null) ?
                                    buissMainImgDeskTop : buissMainImg}")`
                        }
                        }>
                            {(notGettinOrders != "") ?
                                <div data-nosnippet className={"no-render imgWrapDivClose imgWrapDivCloseSmall"}>
                                    <span>{notGettinOrders}</span>
                                </div> : ""}

                        </div>
                    </div>

                    <div className={"restDetailsBlk"}>
                        <div className={"restPHeads"}>
                            <div className="fullRow overflowHid spaceButtomBig">
                                <h1 className={"restHeader HeaderFont anakMeod"}>{currentPage.buissName}</h1>
                                {/* <p className="onPhoneP">{onlyPhoneOrders}</p> */}
                                {currentPage.buisReservation && <>
                                    <div onClick={() => orderMeATable()} className="OrderTableBtn pointing">
                                        הזמנת שולחן
                                    </div>
                                </>
                                }
                            </div>
                            {(currentPage.buissRedirecting && currentPage.buissRedirecting !== "") ?
                                <a href={currentPage.buissRedirecting}>
                                    <div className={`restSubHeader ${!mobiOrNot ? "buissRedirectingBtn" : "OrderTableBtn"} big spaceTop`}>{currentPage.buissKitchType}</div>
                                </a>

                                :
                                <div className={"restSubHeader big spaceTop"}>{currentPage.buissKitchType}</div>

                            }
                            <div className={"detailsLine kosherLine mediumst spaceTop"}>
                                <div> {currentPage.txtBuisKosher}</div>
                            </div>

                        </div>

                        {(mobiOrNot) ?
                            <>
                                <div className={"rSideMedBig"}>
                                    {openingHours}
                                    {buisAddress}
                                    {delTiming}
                                    {rating}

                                </div>
                                <div className={"lSideMedSmall"}>
                                    {lsidetuff}
                                </div>
                            </>
                            : ""}
                    </div>


                    {restMnu}

                </div>

            }

            {
                doDeliver || doTakeAway ?
                    // <ContinueToPayLine theTxt={"המשך להזמנה"} withPrice={true} stickyShow={(tr) => setStickyShow(tr)} />
                    <ContinueToPayLine theTxt={"המשך להזמנה"} withPrice={true} />
                    : ""
            }
            {
                <MoreDetails showMe={shHidMoreDetails} currP={currentPage}
                    setShowMe={() => setShHidMoreDetails(!shHidMoreDetails)}
                    popMoreDetails={() => popMoreDetails()} />
            }

            {thePopUp}


            <ScrollingSaver pagNam={"restPage"} />

            <Helmet
                meta={[
                    {
                        property: "og:url", content:
                            // `${ConnUrls.siteDomainNam}/restaurants/${encodeURIComponent(currentPage.pageUrlFix)}`
                            `${ConnUrls.siteDomainNam}/restaurants/${currentPage.pageUrlFix}`
                                !== null &&
                                encodeURIComponent(currentPage.pageUrlFix) !== "" ?
                                `${ConnUrls.siteDomainNam}/restaurants/${encodeURIComponent(currentPage.pageUrlFix)}` :
                                currentPage._id
                    }
                ]}>



                <link rel="canonical"
                    href={`${ConnUrls.siteDomainNam}/restaurants/${currentPage.pageUrlFix}`
                        !== null &&
                        encodeURIComponent(currentPage.pageUrlFix) !== "" ?
                        `${ConnUrls.siteDomainNam}/restaurants/${encodeURIComponent(currentPage.pageUrlFix)}` :
                        currentPage._id} />

                <script className='structured-data-list' type="application/ld+json">{googleSchem}</script>
            </Helmet>
        </div >
    );
}

export default RestaurantPage;
