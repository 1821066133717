import React, { useState } from 'react';
import { Close } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const PopUp2Buttons = (props) => {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        props.closeFunc();
    }
    return (
        <Dialog open={open} className={`textSizeDiag popDiagBlock ${props.cssStyl || ""}`}>
            <div className="headAndBtnWrap spaceButtomBig">
                <div className="backS">
                    <Close className="pointing" onClick={() => handleClose()} />
                </div>
                <div className="bold veryBig spacePadBig">
                    {(props.topSlides != null) ?
                        props.topSlides : ""
                    }
                </div>

                <div className="lineDotted"></div>
            </div>


            <div className="firstMastSec">
                <div className="insideFrame">
                    <DialogTitle className="regularFont noPadding normalLine">
                        {props.Title.map((txtItm, index) => (
                            <span className="big arrowing" key={index}>{txtItm}</span>
                        ))}
                    </DialogTitle>
                    <DialogContent className="noPadding normalLine">
                        <DialogContentText className="spaceButtomBig">
                            {props.ContentText.map((txtItm, index) => (
                                <span className="big arrowing" key={index}>{txtItm}<br /></span>
                            ))}
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <div className="fullRow">
                            <div className="fullRow">
                                <div className="connectBtn conClear bold big pointing"
                                    onClick={() => props.btn1Clck()}>{props.btn1Txt}</div>
                            </div>
                            <br />
                            <div className="fullRow">
                                <div className="connectBtn conClear2 bold big pointing"
                                    onClick={() => props.btn2Clck()}>{props.btn2Txt}</div>
                            </div>
                        </div>
                    </DialogActions>
                </div>
            </div>
        </Dialog>
    );
};

export default PopUp2Buttons;
